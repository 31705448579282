<template>
  <div class="notfound" v-if="!isProcessing">
    <div class="notfound__text">
      <h1 class="notfound__text__title">404</h1>
      <p class="notfound__text__explain">Sorry, the page you are looking for cannot be found.</p>
      <v-btn class="notfound__text__btn" outlined rounded color="orange lighten-1" @click="$router.push({ name: 'list' })">QRコードの一覧へ戻る</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notfound',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.notfound {
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      font-size: 6.4rem;
      color: $red_color;
    }
    &__explain {
      margin: 0;
      font-size: 1.5rem;
    }
    &__btn {
      margin-top: 20px;
    }
  }
}
</style>
